<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';

	let className = '';

	export let isExpanded = false;

	export { className as class };
</script>

{#if isExpanded}
	<div
		on:click={() => (isExpanded = false)}
		on:keydown={(e) => {
			if (e.key === 'Escape') {
				isExpanded = false;
			}
		}}
		role="button"
		tabindex="0"
		class="absolute w-full h-screen left-0 top-0"
	/>
{/if}
<div
	on:click={() => {
		isExpanded = !isExpanded;
	}}
	on:keydown={(e) => {
		if (e.key === 'Enter') {
			isExpanded = !isExpanded;
		}
	}}
	role="button"
	tabindex="0"
	class="relative cursor-pointer"
>
	<slot />
	<div
		class={classes(
			'absolute bg-white p-3 rounded-lg transition transform duration-400 top-12 right-0 shadow-lg',
			className,
			isExpanded ? 'opacity-100' : 'opacity-0 pointer-events-none -translate-y-14'
		)}
	>
		<slot name="opened-content" />
	</div>
</div>
